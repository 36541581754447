<template>
  <transition name="fade" mode="out-in">
    <div v-if="Object.keys(property).length" class="theme-dewaele">
      <PropertyImageCarousel :carousel-images="property.pictures" />
      <div class="mx-6 md:mx-22 h-full">
        <div class="relative flex flex-row">
          <a
            href="https://www.dewaele.com/"
            class="z-10 flex-shrink-0 h-16 w-16 md:h-32 md:w-32 transform -translate-y-1/2 overflow-hidden"
          >
            <img
              src="@/assets/logo_dewaelevastgoedgroep.png"
              alt="Dewaele Vastgoed Groep"
              class="object-contain h-full w-full text-center leading-8"
            />
          </a>
          <div class="flex flex-wrap my-auto pt-4">
            <div
              v-for="(info, index) in propertyInfo"
              :key="index"
              class="px-4 md:px-7.5 tg-mobile-body-small md:tg-desktop-body"
            >
              <i :class="['fal mr-1', info.icon]" />
              <span v-text="info.value" />
            </div>
          </div>
        </div>
        <div
          class="my-7.5 -mx-6 lg:-mx-22 lg:justify-center grid place-items-center grid-flow-col gap-7.5 tg-mobile-body border-b-0.5 border-gray-cc disable-scrollbars overflow-x-scroll"
        >
          <router-link
            v-for="(link, index) in propertyNavLinks"
            :key="index"
            :to="link.to"
            active-class="text-brand-primary border-b tg-mobile-body-bold"
            class="px-2 pb-2 border-brand-primary hover:border-b hover:text-brand-primary hover:tg-mobile-body-bold whitespace-nowrap"
          >
            {{ link.name }}
          </router-link>
        </div>
        <transition name="fade" mode="out-in">
          <router-view class="my-12 mx-auto max-w-2xl" />
          <!-- We don't use a key here because we want a smooth transition between tabs, and not page recreation. -->
          <!-- Key might be needed when we want to add links to other properties on a property page. -->
        </transition>
      </div>
    </div>
    <i
      v-else
      class="fal fa-spinner-third animate-spin absolute text-3xl top-1/2 left-1/2"
    />
  </transition>
</template>

<script>
import PropertyImageCarousel from '@/components/PropertyImageCarousel';

import { requestJWT } from '@/services/apiService';
import { mapActions, mapGetters, mapState } from 'vuex';
import { currency } from '@/helpers';

export default {
  name: 'PropertyLayout',
  components: {
    PropertyImageCarousel
  },
  data() {
    return {
      propertyId: this.$route.params.propertyId,
      showBidPage: false
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('property', ['property']),

    propertyNavLinks() {
      const links = [
        {
          to: { name: 'PropertyDetail' },
          name: this.$t('property_detail')
        },
        {
          to: { name: 'PropertyVisitTimeSlots' },
          name: this.$t('property_visit')
        }
      ];

      if (this.showBidPage) {
        links.push({
          to: { name: 'PropertyBid' },
          name: this.$t('make_an_offer')
        });
      }

      return links;
    },
    propertyInfo() {
      let info = [],
        { street, number, box, city, price, reference } = this.property;

      info = [
        {
          icon: 'fa-map-marker',
          value: `${street} ${number} ${box} ${city?.zip_code} ${
            city ? city['name_' + this.$i18n.locale] : ''
          }`
        },
        {
          icon: 'fa-coins',
          value: currency(price)
        },
        {
          icon: 'fa-tag',
          value: reference
        }
      ];

      return info;
    }
  },
  created() {
    this.init();
  },
  updated() {
    let activeRouterLink = document.querySelector('[aria-current="page"]');

    if (activeRouterLink) {
      activeRouterLink.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center'
      });
    }
  },
  methods: {
    ...mapActions('auth', ['setJWT', 'logout']),
    ...mapActions('property', ['fetchPropertyDetails']),
    async init() {
      try {
        const { email, secret } = this.$route.query;

        if (email && secret) {
          this.logout();
          const response = await requestJWT({ email, secret });
          const { access, refresh } = response.data;
          this.setJWT({ access, refresh });
        }

        const response = await this.fetchPropertyDetails(this.propertyId);
        if (response?.data?.bid_type === 1) this.showBidPage = true; // 1 is "Gesloten bieding" (closed bid)
        if (this.$route.name == 'PropertyBid' && !this.showBidPage) {
          this.$router.replace({ name: 'PropertyDetail' });
        }
        return response;
      } catch (error) {
        const status = error.response?.status;
        const message = [401, 403].includes(status)
          ? this.$t('could_not_authenticate')
          : this.$t('error_property_details');

        this.$store.dispatch('snackbar/show', {
          type: 'error',
          messageBold: status,
          message
        });
      }
    }
  }
};
</script>
